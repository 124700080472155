import PropTypes from 'prop-types';
import ContentRevealButton from '../../atoms/contentRevealButton';

const CardFooter = ({ data, revealContent, contentRevealHandler, lowerIndex, props }) => {
    const checkValidDetail = () => {
        return data.detail && JSON.stringify(data.detail) !== '{}';
    };

    return (
        <div className="card-footer" >
            {/* <ShareButton /> */}
            {checkValidDetail() && <ContentRevealButton revealed={revealContent} onClick={contentRevealHandler} zIndex={lowerIndex ? 0 : 20} />}
        </div>
    )
}

CardFooter.propTypes = {
    data: PropTypes.object,
    revealContent: PropTypes.bool,
    lowerIndex: PropTypes.bool
}

export default CardFooter;