import StatCard from './../../molecules/statCard';
import VideoStatCard from '../../molecules/videoStatCard';
import DoubleStatCard from '../../molecules/doubleStatCard';
import InteractionCard from '../../molecules/interactionCard';

const StatCardGroup = ({ data, props }) => {

    const validateCardData = () => {
        return data.cards && data.cards.length > 0
    }

    return (
        <div className='section-content-wrapper'>
            {data.heading && <h3>{data.heading}</h3>}
            {validateCardData() && <div className='card-group-container'>
                {
                    data.cards && data.cards.map((card, index) => {
                        if (card.type === 'video') {
                            return (<VideoStatCard key={`video-stat-card-${index}`} data={card} />)
                        } else if (card.type === 'double-stat' || card.type === 'double-stat-unified') {
                            return (<DoubleStatCard key={`double-stat-card-${index}`} data={card} />)
                        } else if (card.type === 'quiz') {
                            return (<InteractionCard key={`interaction-card-${index}`} data={card} />);
                        } else {
                            return (<StatCard key={`stat-card-${index}`} data={card} />)
                        }
                    })
                }
            </div>}
        </div>
    )
}

export default StatCardGroup;