import React, { useState, useEffect, useRef } from 'react';
import useScreenSize from '../../../utils/useScreenSize';

const HealthPercentageBar = ({ percent, colorScheme, ...props }) => {
    const chartRef = useRef();
    const [scale, setScale] = useState(1);
    const screen = useScreenSize();

    useEffect(() => {
        let refWidth = chartRef.current.clientWidth;
        if (chartRef.current.clientWidth < 460) {
            setScale(1);
        } else {
            setScale(refWidth / 460);
        }
    }, [screen]);

    return (
        <div ref={chartRef} className={`${'color-' + colorScheme}`} style={{
            width: '100%',
            transform: `scale(${scale}, ${scale})`
        }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="99"
                fill="none"
                viewBox="0 0 460 99"
            >
                <mask id="path-1-inside-1_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M22 36a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2H24V2a2 2 0 00-2-2h-8a2 2 0 00-2 2v10H2a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M24 24v-2a2 2 0 00-2 2h2zm10 0v-2 2zM24 12h-2a2 2 0 002 2v-2zm-12 0v2a2 2 0 002-2h-2zM2 24v2-2zm10 0h2a2 2 0 00-2-2v2zm10 10v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2H24v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4H24v4zM22 2v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4zm0 0v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zM2 14h10v-4H2v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0H2v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4z"
                    mask="url(#path-1-inside-1_31_66)"
                ></path>
                <mask id="path-3-inside-2_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M69.111 36a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M71.111 24v-2a2 2 0 00-2 2h2zm10 0v-2 2zm-10-12h-2a2 2 0 002 2v-2zm-12 0v2a2 2 0 002-2h-2zm-10 12v2-2zm10 0h2a2 2 0 00-2-2v2zm10 10v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4zm0 0v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4z"
                    mask="url(#path-3-inside-2_31_66)"
                ></path>
                <mask id="path-5-inside-3_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M116.222 36a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M118.222 24v-2a2 2 0 00-2 2h2zm10 0v-2 2zm-10-12h-2a2 2 0 002 2v-2zm-12 0v2a2 2 0 002-2h-2zm-10 12v2-2zm10 0h2a1.996 1.996 0 00-2-2v2zm10 10v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4zm0 0v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4z"
                    mask="url(#path-5-inside-3_31_66)"
                ></path>
                <mask id="path-7-inside-4_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M155.333 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M153.333 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a2 2 0 002 2v-2zm-10-10v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-7-inside-4_31_66)"
                ></path>
                <mask id="path-9-inside-5_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M202.444 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M200.444 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a2 2 0 002 2v-2zm-10-10v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-9-inside-5_31_66)"
                ></path>
                <mask id="path-11-inside-6_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M249.556 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M247.556 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a2 2 0 002 2v-2zm-10-10v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-11-inside-6_31_66)"
                ></path>
                <mask id="path-13-inside-7_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M296.667 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M294.667 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a2 2 0 002 2v-2zm-10-10v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-13-inside-7_31_66)"
                ></path>
                <mask id="path-15-inside-8_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M343.778 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M341.778 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a1.996 1.996 0 002 2v-2zm-10-10v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-15-inside-8_31_66)"
                ></path>
                <mask id="path-17-inside-9_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M390.889 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M388.889 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a2 2 0 002 2v-2zm-10-10v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-17-inside-9_31_66)"
                ></path>
                <mask id="path-19-inside-10_31_66" fill="#fff">
                    <path
                        fillRule="evenodd"
                        d="M438 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <path
                    fill="#6D6E6F"
                    d="M436 12v2a2 2 0 002-2h-2zm-10 0v2-2zm10 12h2a2 2 0 00-2-2v2zm12 0v-2a2 2 0 00-2 2h2zm10-12v-2 2zm-10 0h-2a2 2 0 002 2v-2zM438 2v-4a4 4 0 00-4 4h4zm0 10V2h-4v10h4zm-12 2h10v-4h-10v4zm0 0v-4a4 4 0 00-4 4h4zm0 8v-8h-4v8h4zm0 0h-4a4 4 0 004 4v-4zm10 0h-10v4h10v-4zm2 12V24h-4v10h4zm0 0h-4a4 4 0 004 4v-4zm8 0h-8v4h8v-4zm0 0v4a4 4 0 004-4h-4zm0-10v10h4V24h-4zm12-2h-10v4h10v-4zm0 0v4a4 4 0 004-4h-4zm0-8v8h4v-8h-4zm0 0h4a4 4 0 00-4-4v4zm-10 0h10v-4h-10v4zm-2-12v10h4V2h-4zm0 0h4a4 4 0 00-4-4v4zm-8 0h8v-4h-8v4z"
                    mask="url(#path-19-inside-10_31_66)"
                ></path>
                <mask
                    id="mask0_31_66"
                    style={{ maskType: "alpha" }}
                    width={percent + '%'}
                    height="36"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                >
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M14 0a2 2 0 00-2 2v10H2a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2H24V2a2 2 0 00-2-2h-8zM61.111 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM108.222 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM155.333 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM202.444 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM249.556 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM296.667 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM343.778 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM390.889 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8zM438 0a2 2 0 00-2 2v10h-10a2 2 0 00-2 2v8a2 2 0 002 2h10v10a2 2 0 002 2h8a2 2 0 002-2V24h10a2 2 0 002-2v-8a2 2 0 00-2-2h-10V2a2 2 0 00-2-2h-8z"
                        clipRule="evenodd"
                    ></path>
                </mask>
                <g mask="url(#mask0_31_66)">
                    <path fill="currentColor" d="M0 0H460V36H0z"></path>
                </g>
            </svg>
        </div>
    );
}

export default HealthPercentageBar;