import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ShadowHeader = ({ Tag, text, className, length, ...props }) => {
    const headerRef = useRef();

    const generateShadow = (
        distance = 400,
        dirX = -1,
        dirY = -1,
        shadowColor = "purple"
    ) => {
        let shadowStr = ``;
        for (var i = 1; i < distance; i++) {
            if (i < distance - 1) {
                shadowStr += `${i * dirX}px ${i * dirY}px 0 ${shadowColor}, `;
            } else {
                shadowStr += `${i * dirX}px ${i * dirY}px 0 ${shadowColor}`;
            }
        }
        return shadowStr;
    };

    useEffect(() => {
        headerRef.current.style.textShadow = generateShadow(
            length,
            -1,
            -1,
            "#271741"
        );
    });

    return (
        <Tag ref={headerRef} className={className}>
            {text}
        </Tag>
    );

}

ShadowHeader.propTypes = {
    Tag: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    length: PropTypes.number,
    dirX: PropTypes.number,
    dirY: PropTypes.number,
}

export default ShadowHeader;