import React, { useState, useEffect, useRef } from 'react';
import useScreenSize from '../../../utils/useScreenSize';
import PropTypes from 'prop-types';

const CircleCounterChart = ({ columns, amount, percent, colorScheme, ...props }) => {
    const screen = useScreenSize();
    const gridRef = useRef();
    const [gridWidth, setGridWidth] = useState(0);

    useEffect(() => {
        setGridWidth(gridRef.current.offsetWidth);
    }, [screen.screenSize.width]);

    const createRows = (row) => {
        let dots = [];
        let circleW = (gridWidth - (16 * (columns))) / columns;
        let p = amount * percent * 0.01;
        for (let i = 0; i < columns; i++) {
            let id = (row * columns) + (i + 1);
            let className = 'grid-dots';
            if (id < p) {
                className = `${className} highlite ${'bg-' + colorScheme}`
            }
            dots.push(<div key={`grid-dots-${id}`} className={className} style={{ width: `${circleW}px`, height: `${circleW}px` }}></div>)
        }
        return <div key={`circle-counter-row-${row}`} className='circle-counter-row'>{dots}</div>;
    }

    const createDots = () => {
        let rows = amount / columns;
        let rowsElem = []
        for (let i = 0; i < rows; i++) {
            rowsElem.push(createRows(i));
        }
        return rowsElem;
    }

    return (
        <div ref={gridRef} className="circle-counter-chart">
            {createDots()}
        </div>
    )
}

CircleCounterChart.propTypes = {
    columns: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
    colorScheme: PropTypes.string,
}

CircleCounterChart.defaultProps = {
    columns: 10,
    amount: 50,
    colorScheme: 'purple-50-scheme'
}

export default CircleCounterChart;