import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../atoms/slider';

const FluTrendMap = ({ data, props }) => {
    const [count, setCount] = useState(data.length > 1 ? data.length - 1 : 0);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setCount(data.length > 1 ? data.length - 1 : 0);
    }, [data]);

    const changeCallback = (index) => {
        console.log(index)
        setCurrentIndex(index);
    }

    return (
        <div className='flu-trend-container'>
            <div className='flu-map-container'>
                {data.map((item, index) => {
                    return <img key={`flu-trend-image-` + index} className={`flu-trend-image`} src={item.imageUrl}
                        style={{ opacity: currentIndex === index ? 1 : 0 }}
                        alt={`flu trend map ${item.label} status`} />
                })}
            </div>
            <div className="flu-slider-wrapper">
                <Slider max={count} changeCallback={changeCallback} startValue={currentIndex} />
            </div>
            <div className="indicator-label-group">
                {data.map((item, index) => {
                    return <button key={`flu-trend-btn-` + index}
                        onClick={() => { changeCallback(index) }}
                        className={`flu-trend-indicator-btn ${index === currentIndex ? 'selected' : ''}`}>{item.label}</button>
                })}
            </div>
        </div>
    )
}

FluTrendMap.propTypes = {
    data: PropTypes.array.isRequired,
}

FluTrendMap.defaultProps = {
    data: []
}

export default FluTrendMap;