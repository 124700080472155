import { useState, useEffect } from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        width: 0,
        height: 0,
    });

    const [scrollY, setScrollY] = useState(0);
    const [scrollEnd, setScrollEnd] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            let isResizing;
            clearTimeout(isResizing);
            isResizing = setTimeout(function () {
                setScreenSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
                setScrollY(window.scrollY);
                setIsMobile(window.innerWidth <= 768)
                clearTimeout(isResizing);
            }, 100);
        };
        let isScrolling;
        const handleScroll = () => {
            setScrollEnd(false);
            setScrollY(window.scrollY);
            clearTimeout(isScrolling);
            isScrolling = setTimeout(function () {
                setScrollY(window.scrollY);
                setScrollEnd(true);
                clearTimeout(isScrolling);
            }, 500);
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        window.dispatchEvent(new Event('resize'));
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return { screenSize, isMobile, setIsMobile, scrollY, scrollEnd };
};

export default useScreenSize;