import PropTypes from 'prop-types';
import ShadowHeader from '../../atoms/shadowHeader';

const HeroSection = ({ data, props }) => {
  return (
    <section id="hero" data-testid="hero-section" className="bleed-section">
      <video className="hero-bg-video" loop autoPlay muted playsInline>
        <source src="/assets/videos/dr-walking.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-heading-group">
        <div className="hero-heading container">
          <img className="athena-logo" src="/assets/svg/athenahealth-logo.svg" alt="athenahealth logo" />
          {data.headings && data.headings.map((copy, index) => {
            return <ShadowHeader key={`shadow-txt-${index}`} Tag="h1" text={copy} className={`shadow-txt-${index + 1}`} length={800} />
          })}
        </div>
      </div>
    </section>
  );
};

HeroSection.propTypes = {
  data: PropTypes.object.isRequired,
}

HeroSection.defaultProps = {
  data: null
}

export default HeroSection;
