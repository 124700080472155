import './styles/App.scss';
// import ShadowHeader from './components/shadowHeader';
import HeroSection from './components/template/heroSection';
import Navigation from './components/template/navigation';
import StatCardGroup from './components/template/statCardGroup';
import { siteContent } from './data/site-content';
import useScreenSize from './utils/useScreenSize';
import { useEffect } from 'react';

function App() {
  const screen = useScreenSize();

  useEffect(() => {

  }, [screen.scrollY, screen.screenSize])

  return (
    <div className="App">
      <HeroSection data={siteContent.hero} />
      <Navigation data={siteContent.navItems} />

      <section id="statement-section" className='flex-container section-vertical-gap'>
        <h2>{siteContent.statement.heading}</h2>
        <p dangerouslySetInnerHTML={{ __html: siteContent.statement.description }} />
      </section>

      <section id={siteContent.cliniciansSection.id} className='flex-container category-section'>
        <h2>{siteContent.cliniciansSection.heading}</h2>
        {siteContent.cliniciansSection.categories && siteContent.cliniciansSection.categories.map((category, index) => {
          return <StatCardGroup key={siteContent.cliniciansSection.id + '-category-' + index} data={category} />
        })}
      </section>
      <section id={siteContent.practiceSection.id} className='flex-container category-section'>
        <h2>{siteContent.practiceSection.heading}</h2>
        {siteContent.practiceSection.categories && siteContent.practiceSection.categories.map((category, index) => {
          return <StatCardGroup key={siteContent.practiceSection.id + '-category-' + index} data={category} />
        })}
      </section>

      <section id={siteContent.patientsSection.id} className='flex-container category-section'>
        <h2>{siteContent.patientsSection.heading}</h2>
        {siteContent.patientsSection.categories && siteContent.patientsSection.categories.map((category, index) => {
          return <StatCardGroup key={siteContent.patientsSection.id + '-category-' + index} data={category} />
        })}
      </section>

      <section id={siteContent.healthcareSection.id} className='flex-container category-section'>
        <h2>{siteContent.healthcareSection.heading}</h2>
        {siteContent.healthcareSection.categories && siteContent.healthcareSection.categories.map((category, index) => {
          return <StatCardGroup key={siteContent.healthcareSection.id + '-category-' + index} data={category} />
        })}
      </section>
    </div>
  );
}

export default App;
