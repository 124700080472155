import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleCircular from '../../atoms/singleCircular';
import CircleCounterChart from '../../atoms/circleCounter';
import ClockChart from '../../atoms/clockChart';
import HorizontalPercentageBarChart from '../../atoms/horizontalPercentageBar';
import SinglePieChart from '../../atoms/singlePieChart';
import useScreenSize from '../../../utils/useScreenSize';
import { ChartTypes } from '../../../data/constant';
import TabsVerticalBarChart from '../../atoms/tabsVerticalBarChart';
import HealthPercentageBar from '../../atoms/healthPercentageBar';
import FluTrendMap from '../fluTrendMap';
import HorizontalBarChart from '../../atoms/horizontalBar';
import CardFooter from '../cardFooter';
import CardDetail from '../cardDetail';
import DiagnosesLineChart from '../diagnosesLineChart';

const StatCard = ({ data, quizResult, props }) => {
    const [addonClasses, setAddonClasses] = useState('');
    const [revealContent, setRevealContent] = useState(false);
    const screen = useScreenSize();

    useEffect(() => {
        setAddonClasses(` ${data.sizeClass ?? ''} ${screen.isMobile && data.fullSizeOnMobile ? 'mobile-full-card' : ''}`);
    }, [data, screen]);

    const getContentDescription = () => {
        return (
            <div className="content-description">
                {data.showHeading && data.value && (
                    <h4 className="value-label">
                        {data.value}
                        <span className={`unit-label ${'color-' + data.colorScheme}`}>{' ' + data.unit}</span>
                    </h4>
                )}
                {data.description && <p
                    className={`${'color-' + data.colorScheme} ${screen.isMobile ? 'm-sub' : 'l-sub'} description-label`}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                ></p>}
            </div>
        );
    };

    const getChart = () => {
        switch (data.chartType) {
            case ChartTypes.singleCircular:
                return (
                    <SingleCircular strokeWidth={16} min={0} max={100} value={data.value} colorScheme={data.colorScheme} iconUrl={data.chartIcon} />
                );
            case ChartTypes.circleCounter:
                return <CircleCounterChart percent={data.value} colorScheme={data.colorScheme} />
            case ChartTypes.horizontalPercentage:
                return <HorizontalPercentageBarChart percent={data.value} colorScheme={data.colorScheme} />;
            case ChartTypes.healthPercentage:
                return <HealthPercentageBar percent={data.value} colorScheme={data.colorScheme} />
            case ChartTypes.singlePieChart:
                return <SinglePieChart data={data} />;
            case ChartTypes.clock:
                return <ClockChart data={data} />;
            case ChartTypes.horizontalBar:
                return <HorizontalBarChart data={data} />
            default:
                return null;
        }
    };

    const contentRevealHandler = () => {
        setRevealContent(!revealContent);
    };

    const getClockContent = () => {
        return (
            <>
                {getChart()}
                <div className="content-description">
                    {data.description && (
                        <p
                            className={`${screen.isMobile ? 'm-sub' : 'l-sub'} ${'color-' + data.colorScheme} clock-rich-txt`}
                            dangerouslySetInnerHTML={{ __html: data.description }}
                        />
                    )}
                </div>
            </>
        );
    };

    const getSinglePieChartContent = () => {
        return (
            <>
                {getChart()}
                {getContentDescription()}
            </>
        );
    };

    const getCommonContent = () => {
        return (
            <>
                {getContentDescription()}
                {getChart()}
            </>
        );
    };

    const getSimpleVerticalBarContent = () => {
        return <div className="content-description">
            {data.description && (
                <p
                    className={`${screen.isMobile ? 'm-sub' : 'l-sub'} ${'color-' + data.colorScheme} description-label`}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                />
            )}
            <TabsVerticalBarChart data={data} />
        </div>
    }

    const getContentLayout = () => {
        switch (data.chartType) {
            case ChartTypes.clock:
                return getClockContent();
            case ChartTypes.singlePieChart:
                return getSinglePieChartContent();
            case ChartTypes.simpleVerticalBar:
                return getSimpleVerticalBarContent();
            case ChartTypes.tabsVerticalBar:
                return <TabsVerticalBarChart data={data} />;
            case ChartTypes.flueTrendMap:
                return <FluTrendMap data={data.categories} />
            case ChartTypes.diagnosesLineChart:
                return <DiagnosesLineChart data={data} />
            default:
                return getCommonContent();
        }
    };

    const getCopyOnlyTypeLayout = () => {
        if (data.title && data.description) {
            return <>
                {data.title && <h4 className={`m-title ${screen.isMobile ? 'xs-title' : 'sm-title'}`}>{data.title}</h4>}
                {data.description && <p className={`${'color-' + data.colorScheme} description-label ${screen.isMobile ? 'm-sub' : 'l-sub'}`} dangerouslySetInnerHTML={{ __html: data.description }} />}
            </>
        } else if (!data.title && data.description) {
            return <p className={`${'color-' + data.colorScheme} description-label ${screen.isMobile ? 'xs-title' : 'sm-title'}`} dangerouslySetInnerHTML={{ __html: data.description }} />
        }
        return null;
    };

    const getEyebrow = () => {
        if (quizResult) {
            return (
                data.eyebrow +
                ` <span class="${'color-' + data.colorScheme} ${quizResult.isCorrect ? 'correct-icon-after' : 'wrong-icon-after'}">${quizResult.label
                }</span>`
            );
        } else {
            return data.eyebrow;
        }
    };

    return (
        <div className={`card-basic stat-card${addonClasses}`}>
            <div className="card-content-wrapper">
                <div className={`card-content-detail`}>
                    {data.icon && <div className={`card-icon ${'color-' + data.colorScheme}`}><img src={data.icon} alt="card icon" /></div>}
                    {data.eyebrow && <p className="sm-sub card-eyebrow" dangerouslySetInnerHTML={{ __html: getEyebrow() }} />}
                    {data.type === 'stat-copy-only' ? getCopyOnlyTypeLayout() : getContentLayout()}
                </div>
                <CardFooter data={data} revealContent={revealContent} contentRevealHandler={contentRevealHandler} />
            </div>
            <CardDetail data={data} revealContent={revealContent} />
        </div>
    );
};

StatCard.propTypes = {
    data: PropTypes.object.isRequired,
    quizResult: PropTypes.object,
};

export default StatCard;
