import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const HorizontalPercentageBarChart = ({ percent, colorScheme, ...props }) => {

    useEffect(() => {

    }, []);

    return (
        <div className='horizontal-percentage-chart'>
            <div className={`horizontal-percentage-inner ${'bg-' + colorScheme}`} style={{ width: percent + '%' }}></div>
        </div>
    )
}

HorizontalPercentageBarChart.propTypes = {
    percent: PropTypes.number.isRequired,
    colorScheme: PropTypes.string,
}

HorizontalPercentageBarChart.defaultProps = {
    percent: 0,
    colorScheme: 'bg-purple-50-scheme'
}

export default HorizontalPercentageBarChart;