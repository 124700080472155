import PropTypes from 'prop-types';

const ContentRevealButton = ({ revealed, onClick, zIndex, ...props }) => {
    return (
        <button className={`content-reveal-button ${revealed ? 'revealed' : ''}`} onClick={onClick} style={{ zIndex: zIndex }} />
    )
}

ContentRevealButton.propTypes = {
    revealed: PropTypes.bool,
    onClick: PropTypes.func,
}

ContentRevealButton.defaultProps = {
    revealed: false,
    onClick: null
}

export default ContentRevealButton;