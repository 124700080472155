import { useState, useRef, useEffect } from 'react';
import { ChartTypes } from '../../../data/constant';
import PropTypes from 'prop-types';
import TabsButtonGroup from '../tabsButtonGroup';

const TabsVerticalBarChart = ({ data, ...props }) => {
    const containerRef = useRef();
    const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
    const bgColors = ['#622FB4', '#F3A61C', '#DE167C', '#529D2F', '#0466B4', '#9C28B1', '#6D6E6F'];
    const colors = ['#ffffff', '#271741', '#ffffff', '#271741', '#ffffff', '#ffffff', '#ffffff'];
    const [barsData, setBarsData] = useState(data.categories[currentCategoryIndex].items)
    const [hideValue, setHideValue] = useState(false);

    useEffect(() => {
        setHideValue(data.categories[0].hideValue ?? false)
    }, [data])

    const getHeightValue = (value) => {
        let sorted = [...barsData];
        let highestV = sorted.sort((a, b) => b.value - a.value)[0].value;
        return (value / highestV) * 250;
    }

    const getBarColor = (index) => {
        let theIndex = index % 7
        return { color: colors[theIndex], bgColor: bgColors[theIndex] };
    }

    const generateBar = (bar, index) => {
        return <div key={`vertical-bar-${index}`} className='vertical-bar' >
            {bar.icon && <img className='bar-icon' src={bar.icon} />}
            <div className='bar-column'
                style={{
                    height: getHeightValue(bar.value) + 'px',
                    background: `linear-gradient(180deg, ${getBarColor(index).bgColor} 0%, rgba(98, 47, 180, 0) 100%)`
                }}>
                {!hideValue && <p className='xs-sub bar-value-label' style={{ color: getBarColor(index).color }}>{bar.value + ' ' + (bar.unit ? bar.unit : '')}</p>}
            </div>
        </div>
    }

    const getExtraClassPerType = () => {
        if (data.chartType === ChartTypes.tabsVerticalBar) {
            return 'tabs-vertical-bar-chart'
        }
        return 'simple-vertical-bar-chart';
    }

    const getOverflowClass = () => {
        if (data.chartType === ChartTypes.tabsVerticalBar) {
            return 'overflow-x-card';
        }
        return '';
    }

    const updateTabIndex = (index) => {
        setCurrentCategoryIndex(index)
        setHideValue(data.categories[index].hideValue);
        setBarsData(data.categories[index].items)
    }

    return (
        <div className='vertical-bar-wrapper'>
            {data.categories.length > 1 && <TabsButtonGroup categories={data.categories} currentIndex={currentCategoryIndex} updateTabIndexCallback={(index) => {
                updateTabIndex(index)
            }} />}
            <div className={`vertical-bar-content-wrapper ${getOverflowClass()}`}>
                <div className={getExtraClassPerType()}>
                    <div ref={containerRef} className="vertical-bars-container">
                        {barsData && barsData.map((bar, index) => {
                            return generateBar(bar, index);
                        })}
                    </div>
                    <div className='value-label-wrapper'>
                        {barsData && barsData.map((bar, index) => {
                            if (bar.label) {
                                return <div key={'vertical-bar-index-' + index} className='bar-label-wrapper'>
                                    <p className='xs-sub bar-type-label'>{bar.label}</p>
                                </div>
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

TabsVerticalBarChart.propTypes = {
    data: PropTypes.object,
}

export default TabsVerticalBarChart;