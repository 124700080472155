import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ContentRevealButton from '../../atoms/contentRevealButton';
import useScreenSize from '../../../utils/useScreenSize';
import CardFooter from '../cardFooter';
import CardDetail from '../cardDetail';

const VideoStatCard = ({ data, props }) => {
    const [addonClasses, setAddonClasses] = useState('');
    const [revealContent, setRevealContent] = useState(false);
    const screen = useScreenSize();

    useEffect(() => {
        setAddonClasses(`${data.sizeClass} ${data.alignmentClass}`)
    }, [data]);

    const contentRevealHandler = () => {
        setRevealContent(!revealContent);
    }

    const getVideoContent = () => {
        if (data.quote) {
            return <>
                {data.quote && <p className={`xs-title ${'color-' + data.colorScheme}`} dangerouslySetInnerHTML={{ __html: data.quote }} />}
                <p className={`m-sub description-label`} dangerouslySetInnerHTML={{ __html: data.description }} />
            </>
        } else {
            return <p className={`${screen.isMobile ? 'xs-title' : 'sm-title'} ${'color-' + data.colorScheme} description-label`} dangerouslySetInnerHTML={{ __html: data.description }} />
        }
    }

    return (
        <div className={`video-card-basic ${addonClasses}`}>
            <div className="video-card-content-detail">
                <div className="card-content">
                    <div className='video-card-detail'>
                        {getVideoContent()}
                    </div>
                    <CardFooter data={data} revealContent={revealContent} contentRevealHandler={contentRevealHandler} lowerIndex={true} />
                </div>
                <div className='video-wrapper'>
                    <iframe className='video-iframe' title="athenahealth video" src="//players.brightcove.net/1752604059001/VyqgG8mql_default/index.html?videoId=4093643993001" allowFullScreen webkitallowfullscreen="true"
                        mozallowfullscreen="true"></iframe>
                    {/* <iframe className='video-iframe' src="https://www.youtube.com/embed/Xm4Tv-K30AA?si=MZtD9TeWDO6tVl7P" title="YouTube video player" allow="autoplay; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                </div>
            </div>
            <CardDetail data={data} revealContent={revealContent}>
                <ContentRevealButton revealed={revealContent} onClick={contentRevealHandler} />
            </CardDetail>
        </div>
    )
}

VideoStatCard.propTypes = {
    data: PropTypes.object,
};

export default VideoStatCard;