import PropTypes from 'prop-types';

const CardDetail = ({ children, data, revealContent, props }) => {
    return (
        <div
            className="revealed-content-wrapper"
            style={{ transform: revealContent ? 'translateY(0px)' : 'translateY(100%)', pointerEvents: revealContent ? 'auto' : 'none' }}
        >
            <div className="reveal-content-detail">
                {data && <p>content placeholder</p>}
            </div>
            {children}
        </div>
    )
}

CardDetail.propTypes = {
    children: PropTypes.element,
    data: PropTypes.object,
    revealContent: PropTypes.bool,
};

export default CardDetail;