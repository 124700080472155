import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

const HorizontalBarChart = ({ data, props }) => {
    const containerRef = useRef();
    const bgColors = ['#622FB4', '#F3A61C', '#DE167C', '#529D2F', '#0466B4', '#9C28B1', '#6D6E6F'];
    const colors = ['#ffffff', '#271741', '#ffffff', '#271741', '#ffffff', '#ffffff', '#ffffff'];
    const [barHeight, setBarHeight] = useState(20);

    useEffect(() => {
        setBarHeight(containerRef.current.clientHeight / data.categories.length)
    }, [containerRef, data])

    const getWidthValue = (value) => {
        let sorted = [...data.categories];
        let highestV = sorted.sort((a, b) => b.value - a.value)[0].value;
        return (value / highestV) * 70;
    }

    const getBarColor = (index) => {
        let theIndex = index % 7
        return { color: colors[theIndex], bgColor: bgColors[theIndex] };
    }

    const getArrow = (color) => {
        return <div className='pointy-arrow' style={{ height: barHeight }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 52 85"
            >
                <path
                    fill={color}
                    d="M.175 0a32 32 0 0123.3 10.068l25.365 26.95a8 8 0 010 10.966L23.477 74.932A32 32 0 01.172 85H0V0h.175z"
                ></path>
            </svg>
        </div>
    }

    return (
        <div className='horizontal-bar-chart' ref={containerRef}>
            {data.categories && data.categories.map((item, index) => {
                return <div key={`horizontal-bar-item-${index}`} className='horizontal-bar-item' style={{ height: barHeight }}>
                    <div className={`inner-bar`} style={{
                        width: getWidthValue(item.value) + '%',
                        height: "100%",
                        background: `linear-gradient(-90deg, ${getBarColor(index).bgColor} 0%, rgba(98, 47, 180, 0) 100%)`
                    }}>

                    </div>
                    {item.pointyCorner && getArrow(getBarColor(index).bgColor)}
                    <p className='sm-sub bar-label'>{item.label}</p>
                </div>
            })}
        </div>
    )
}

HorizontalBarChart.propTypes = {
    data: PropTypes.object.isRequired,
}

HorizontalBarChart.defaultProps = {
    data: null
}

export default HorizontalBarChart;