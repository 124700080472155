import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ClockChart = ({ data, ...props }) => {
    const topCircleRef = useRef(null);
    const [circlePathLength, setCirclePathLength] = useState(0);
    const [percent, setPercent] = useState({ value: 40 });
    const dimension = 220;
    const strokeWidth = 10;
    const [showMinsPie, setShowMinsPie] = useState(false);
    const [hours, setHours] = useState(data.hours ?? 0);
    const [mins, setMins] = useState(data.mins ?? 0);

    useEffect(() => {
        if (data) {
            setHours(data.hours ?? 0);
            setMins(data.mins ?? 0);
        } else {
            setHours(0);
            setMins(0);
        }
    }, [data]);

    useEffect(() => {
        if (topCircleRef.current.getTotalLength) {
            setCirclePathLength(topCircleRef.current.getTotalLength());
        }
        if (hours > 0) {
            // hour focus
            //setPercent({ value: 4.16 * data.hours })
            setPercent({ value: 8.333 * hours })
            setShowMinsPie(false)
        } else if (hours <= 0) {
            // mins focus
            setPercent({ value: (mins / 60) * 100 })
            setShowMinsPie(true)
        }
    }, [hours, mins])

    return (
        <div className={`clock-chart ${'color-' + data.colorScheme}`} >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={dimension}
                height={dimension}
                fill="transparent"
                viewBox={`0 0 ${dimension} ${dimension}`}
                style={{ transform: "rotate(-0.25turn)" }}
            >
                <circle
                    fill="transparent"
                    stroke="#6D6E6F"
                    strokeWidth={strokeWidth}
                    cx="50%"
                    cy="50%"
                    r={(dimension / 2) - strokeWidth}
                    opacity="0.3"
                />
                {showMinsPie && <circle cx="50%"
                    cy="50%"
                    stroke="currentColor"
                    opacity="0.5"
                    r={dimension / 5}
                    strokeWidth="40%"
                    strokeDasharray={`${(percent.value * 2.75)}, ${circlePathLength}`} />}
                <circle
                    ref={topCircleRef}
                    fill="transparent"
                    //stroke={color}
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    cx="50%"
                    cy="50%"
                    r={(dimension / 2) - strokeWidth}
                    strokeLinecap="round"
                    strokeDasharray={circlePathLength}
                    strokeDashoffset={circlePathLength - circlePathLength * (percent.value / 100)}
                />
            </svg>
            <div className="clock-hands">
                <div className='hour-hand' style={{ transform: `rotate(${30 * hours}deg) translateY(-50%)` }} />
                <div className='mins-hand' style={{ transform: `rotate(${6 * mins}deg) translateY(-50%)` }} />
            </div>
        </div>
    )
}

ClockChart.propTypes = {
    data: PropTypes.object.isRequired,

}

ClockChart.defaultProps = {
    data: null,
}

export default ClockChart;