
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../../../utils/useScreenSize';

const Navigation = ({ data, props }) => {
    const screen = useScreenSize();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedLabel, setSelectedLabel] = useState("Choose a category");
    const [expanded, setExpanded] = useState(false);
    const [overrideScroll, setOverrideScroll] = useState(false);
    const [hash, setHash] = useState(window.location.hash);

    useEffect(() => {
        // this is for deeplink scroll to section
        if (hash) {
            setOverrideScroll(true);
            let hashId = hash.replace('#', '');
            let timer = setTimeout(() => {
                let focusedBtn = document.getElementById(hashId + '-btn');
                if (focusedBtn) {
                    focusedBtn.click();
                }

                clearTimeout(timer)
            }, 500)
        }
    }, [hash])

    useEffect(() => {
        // this is for updating nav hight light based on scroll position 
        if (!overrideScroll) {
            let firstSection = document.getElementById(data[0].id);
            let firstSectionY = 0;
            if (firstSection && firstSection.getBoundingClientRect) {
                firstSectionY = firstSection.getBoundingClientRect().top
            }
            if (screen.scrollY < firstSectionY) {
                setSelectedIndex(-1);
                setSelectedLabel("Choose a category");
                return;
            }
            data.forEach((navItem, index) => {
                let section = document.getElementById(navItem.id);
                if (section && section.getBoundingClientRect) {
                    let sectionH = section.getBoundingClientRect().height;
                    let sectionY = section.getBoundingClientRect().top;
                    if (sectionY < 0 && sectionY > (sectionH * -1) + 300) {
                        // console.log('section')
                        setSelectedIndex(index)
                        setSelectedLabel(navItem.label);
                    }
                }
            });
        }
    }, [data, screen.scrollY, overrideScroll])

    useEffect(() => {
        // scrollend reset
        if (screen.scrollEnd) {
            setOverrideScroll(false);
            setHash('');
        }
    }, [screen.scrollEnd])


    const updateSelection = (label, index) => {
        // console.log("on click!!!!", label, index)
        setExpanded(false);
        setSelectedLabel(label);
        setSelectedIndex(index);
        setOverrideScroll(true);
    }

    const getNavigationLayout = () => {
        if (screen.isMobile) {
            return <nav id='category-nav-mobile' data-testid="category-nav" className={expanded ? 'expanded' : ''} >
                <div id="mobile-nav-bg-cover">
                    <div id="mobile-nav-wrapper" >
                        <button id="main-nav-choice-btn" className='sm-sub' onClick={() => {
                            setExpanded(!expanded);
                        }}>{selectedLabel}</button>
                        <ul id="mobile-nav-item-list" className='container' data-testid="mobile-category-nav-list">
                            {data.map((item, index) => {
                                return <li key={'nav-item-' + index} >
                                    <a id={item.id + '-btn'} className={`nav-item xl-sub ${selectedIndex === index ? 'selected' : ''}`} href={"#" + item.id} onClick={() => {
                                        updateSelection(item.label, index);
                                    }}>{item.label}</a>
                                </li>
                            })}
                            <div id="mobile-nav-footer">
                                <img className="small-athena-logo" src="/assets/svg/small-athenahealth-logo.svg" alt="athenahealth logo" />
                                <p className='description sm-sub'>Learn more about athenahealth</p>
                                <a className="athenahealth-link m-sub" href="https://www.athenahealth.com/" target="_blank">Visit athenahealth.com</a>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>;
        } else {
            return <nav id='category-nav' data-testid="category-nav">
                <ul id="nav-item-wrapper" className='container'>
                    {data.map((item, index) => {
                        return <li key={'nav-item-' + index} data-testid="category-nav-list">
                            <a id={item.id + '-btn'} className={`nav-item ${selectedIndex === index ? 'selected' : ''}`}
                                href={"#" + item.id}
                                onClick={() => {
                                    updateSelection(item.label, index);
                                }}
                            >{item.label}</a>
                        </li>
                    })}
                </ul>
            </nav>
        }
    }

    return (
        <>
            {data && getNavigationLayout()}
        </>
    )
}

Navigation.propTypes = {
    data: PropTypes.array,
}

export default Navigation;