import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Slider = ({ max, startValue, changeCallback, props }) => {
    const [value, changeValue] = useState(startValue)

    useEffect(() => {
        changeValue(startValue)
    }, [startValue])

    const updateSliderProgress = () => {
        let percentage = parseFloat(value / max) * 100;
        return 'linear-gradient(to right, rgb(243, 166, 28) 0%, rgb(243, 166, 28)' + percentage + '%, rgb(154, 155, 155)' + percentage + '%, rgb(154, 155, 155) 100%)';
    }

    const changeHandler = (e) => {
        changeValue(e.target.value)
        if (changeCallback) {
            changeCallback(Number(e.target.value));
        }
    }

    return (
        <div className='stat-card-slider'>
            <input type="range" min="0" max={`${max}`} value={value} className="slider" style={{ background: updateSliderProgress() }} onChange={changeHandler}></input>
        </div>
    )
}

Slider.propTypes = {
    max: PropTypes.number.isRequired,
    startValue: PropTypes.number.isRequired,
    changeCallback: PropTypes.func
}

Slider.defaultProps = {
    max: 2,
    startValue: 0,
    changeCallback: null
}

export default Slider;