import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../../../utils/useScreenSize';

const SinglePieChart = ({ data, ...props }) => {
    const screen = useScreenSize();
    const chartRef = useRef();
    const topCircleRef = useRef(null);
    const [circlePathLength, setCirclePathLength] = useState(0);
    const [percent, setPercent] = useState({ value: data.value });
    const dimension = 220;
    const strokeWidth = "40%";
    const [scale, setScale] = useState(1.5)

    useEffect(() => {
        setPercent({ value: data.value })
        if (topCircleRef.current.getTotalLength) {
            setCirclePathLength(topCircleRef.current.getTotalLength());
        }
    }, [data]);

    useEffect(() => {
        console.log(chartRef.current.clientWidth)
        if (screen.isMobile) {
            setScale(0.2 + (chartRef.current.clientWidth / dimension));
        } else {
            setScale(1.2);
        }
    }, [screen.isMobile, screen.screenSize])

    return (
        <div ref={chartRef} className="single-pie-wrapper" >
            <div className={`single-pie ${'color-' + data.colorScheme}`} style={{ transform: `scale(${scale},${scale})`, marginTop: 20 * scale }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={dimension}
                    height={dimension}
                    fill="transparent"
                    viewBox={`0 0 ${dimension} ${dimension}`}
                    style={{ transform: "rotate(-0.25turn)" }}
                >
                    <circle
                        ref={topCircleRef}
                        fill="#6D6E6F"
                        cx="50%"
                        cy="50%"
                        r={(dimension / 2.5)}
                        opacity="0.3"
                    />
                    <circle
                        ref={topCircleRef}
                        cx="50%"
                        cy="50%"
                        stroke="currentColor"
                        r={dimension / 5}
                        strokeWidth={strokeWidth}
                        strokeDasharray={`${(percent.value * 2.75)}, ${circlePathLength}`} />
                </svg>
            </div>
        </div>
    )
}

SinglePieChart.propTypes = {
    data: PropTypes.object.isRequired,

}

SinglePieChart.defaultProps = {
    data: null,
}

export default SinglePieChart;