export const ChartTypes = {
  video: 'video',
  clock: 'clock',
  circleCounter: 'circle-counter',
  singleCircular: 'single-circular',
  horizontalPercentage: 'horizontal-percentage',
  healthPercentage: 'health-percentage',
  singlePieChart: 'single-pie-chart',
  mapPercentage: 'map-percentage',
  tabsVerticalBar: 'tabs-vertical-bar',
  simpleVerticalBar: 'simple-vertical-bar',
  flueTrendMap: 'flu-trend-map',
  diagnosesLineChart: 'diagnoses-line-chart',
  horizontalBar: 'horizontal-bar',
};

export const StatCardSizeClasses = {
  quarter: 'quarter-card',
  half: 'half-card',
  fourty: 'fourty-card',
  sixty: 'sixty-card',
  seventyFive: 'seventy-five-card',
  fullCard: 'full-card',
};
