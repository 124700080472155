import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../../../utils/useScreenSize';

const TabsButtonGroup = ({ categories, currentIndex, updateTabIndexCallback, props }) => {
    const screen = useScreenSize();
    const [expanded, setExpanded] = useState(false);
    const [displayLabel, setDisplayLabel] = useState("Choose a category")

    const getCategoryTabs = () => {
        if (screen.isMobile) {
            return <div className={`tabs-button-group-mobile ${expanded ? 'expanded' : ''}`}>
                <button className="tabs-group-dropdown-btn sm-sub" onClick={() => {
                    setExpanded(!expanded);
                }}>{displayLabel}</button>
                <ul className='tabs-btn-dropdown-list'>
                    {categories.map((category, index) => {
                        return <li key={`tabs-category-item-${index}`}>
                            <button className='tabs-category-item-btn md-sub' onClick={() => {
                                if (updateTabIndexCallback) {
                                    updateTabIndexCallback(index);
                                    setExpanded(false);
                                    setDisplayLabel(category.label)
                                }
                            }}>{category.label}</button>
                        </li>
                    })}
                </ul>
            </div>
        } else {
            let percentageW = categories ? 100 / categories.length : 33;
            return <div className="tabs-button-group-desktop">
                {categories.map((category, index) => {
                    return <button key={`tabs-btn-${index}`}
                        className={`tabs-btn ${index === currentIndex ? 'selected' : ''}`}
                        style={{ width: percentageW + "%" }}
                        onClick={() => {
                            if (updateTabIndexCallback) {
                                updateTabIndexCallback(index);
                            }
                        }}
                    >
                        <p>{category.label}</p>
                    </button>
                })}
                <div className="tabs-selected-highlite-bar" style={{ width: percentageW + "%", left: percentageW * currentIndex + '%' }}></div>
            </div >;
        }
    }

    return (
        <>
            {categories && getCategoryTabs()}
        </>
    )
}

TabsButtonGroup.propTypes = {
    categories: PropTypes.array,
    currentIndex: PropTypes.number,
    updateTabIndexCallback: PropTypes.func
}

export default TabsButtonGroup;