import { useState } from 'react';
import PropTypes from 'prop-types';
import TabsButtonGroup from '../../atoms/tabsButtonGroup';

const DiagnosesLineChart = ({ data, props }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const updateTabIndexCallback = (index) => {
        setCurrentIndex(index)
    }

    return (
        <div className="diagnoses-line-chart">
            <TabsButtonGroup categories={data.categories} currentIndex={currentIndex} updateTabIndexCallback={updateTabIndexCallback} />
            <div className="overflow-x-card">
                <div className='diagnoses-line-chart-container'>
                    {data.categories.map((item, index) => {
                        return <img key={`diagnoses-line-chart-` + index} className={`diagnoses-line-chart-image`} src={item.imageUrl}
                            style={{ opacity: currentIndex === index ? 1 : 0 }}
                            alt={`${item.label} line chart`} />
                    })}
                </div>
            </div>
        </div>
    )
}

DiagnosesLineChart.propTypes = {
    data: PropTypes.object,
}

export default DiagnosesLineChart;