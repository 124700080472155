import { useEffect, useRef, useState } from 'react';
import useScreenSize from '../../../utils/useScreenSize';
import PropTypes from 'prop-types';

const SingleCircular = ({ strokeWidth, min, max, value, colorScheme, iconUrl, props }) => {
    const screen = useScreenSize();
    const cardRef = useRef();
    const topCircleRef = useRef(null);
    const [circlePathLength, setCirclePathLength] = useState(0);
    const [dimension, setDimension] = useState(220);
    const [percent, setPercent] = useState({ value: (value / max) * 100 });

    useEffect(() => {
        if (topCircleRef.current.getTotalLength) {
            setCirclePathLength(topCircleRef.current.getTotalLength());
        }
        let cardWidth = cardRef.current.offsetWidth;
        setDimension(cardWidth);
    }, [screen.screenSize])

    useEffect(() => {
        setPercent({ value: (value / max) * 100 });
    }, [value, max])

    return (
        <div ref={cardRef} className={`single-circular-chart ${'color-' + colorScheme}`}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={dimension}
                height={dimension}
                fill="transparent"
                viewBox={`0 0 ${dimension} ${dimension}`}
            >
                <circle
                    fill="transparent"
                    stroke="#6D6E6F"
                    strokeWidth={strokeWidth}
                    cx="50%"
                    cy="50%"
                    r={(dimension / 2) - strokeWidth}
                    opacity="0.3"
                ></circle>
                <circle
                    ref={topCircleRef}
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth={strokeWidth}
                    cx="50%"
                    cy="50%"
                    r={(dimension / 2) - strokeWidth}
                    transform={`rotate(-90, ${dimension / 2}, ${dimension / 2})`}
                    strokeLinecap="round"
                    strokeDasharray={circlePathLength}
                    strokeDashoffset={circlePathLength - circlePathLength * (percent.value / 100)}
                ></circle>
            </svg>
            {iconUrl && iconUrl.length > 0 && <img className="single-circle-chart-icon" src={iconUrl} alt="single circle chart icon" />}
        </div>
    )
}

SingleCircular.propTypes = {
    strokeWidth: PropTypes.number.isRequired,
    colorScheme: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    iconUrl: PropTypes.string,
}

SingleCircular.defaultProps = {
    strokeWidth: 16,
    colorScheme: 'purple-50-scheme',
    min: 0,
    max: 100,
    value: 0,
    iconUrl: ''
}

export default SingleCircular;