export const siteContent = {
  hero: {
    headings: ['athenahealth', 'Data Institute'],
    video_url: '',
  },
  navItems: [
    {
      id: 'clinician-section',
      label: 'Clinician',
    },
    {
      id: 'practice-section',
      label: 'Practice',
    },
    {
      id: 'patients-section',
      label: 'Patients',
    },
    {
      id: 'healthcare-section',
      label: 'Healthcare',
    },
  ],
  statement: {
    heading: 'How well do you know the current state of American healthcare?',
    description:
      'Using over <span>13B+</span> data points we’ve compiled this snapshot of healthcare trends, from patients to providers and everything in between. ',
  },
  cliniciansSection: {
    id: 'clinician-section',
    heading: 'Clinician Trends',
    categories: [
      {
        heading: 'Overall Industry',
        cards: [
          {
            type: 'stat',
            chartType: 'circle-counter',
            sizeClass: 'half-card',
            colorScheme: 'purple-50-scheme',
            showHeading: true,
            chartIcon: '',
            value: 95,
            unit: '%',
            description: "of clinicians <span>can't find the patient data</span> they need in the moments of care",
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'half-card',
            colorScheme: 'purple-50-scheme',
            showHeading: true,
            chartIcon: '/assets/svg/battery-icon.svg',
            value: 57,
            unit: '%',
            description: 'of admin staff are worried they will <span>burn out due to the high volume of repetitive tasks</span>',
            detail: { heading: '', description: '' },
          },
          {
            type: 'video',
            alignmentClass: 'video-right',
            sizeClass: 'full-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            quote: '“10 extra years of school didn’t prepare me for the reality of the situation.”',
            description: 'Dr. Charles Baker talks about the healthcare IT learning curve for doctors just entering the industry.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            chartIcon: '/assets/svg/document-paper-icon.svg',
            value: 91,
            unit: '%',
            description: 'of physicians agree that the <span>burden of regulatory requirements is getting worse</span>',
            detail: { heading: '', description: '' },
          },
          {
            type: 'quiz',
            sizeClass: 'half-card',
            fullSizeOnMobile: false,
            quiz: {
              type: 'radio-long',
              question: 'Which of the following did physicians say is the top issue impacting their organization?',
              options: [
                {
                  label: 'Regulatory requirements',
                  isCorrect: false,
                },
                {
                  label: 'Staffing shortages',
                  isCorrect: true,
                },
                {
                  label: 'Repetitive tasks',
                  isCorrect: false,
                },
                {
                  label: 'Outdated technology',
                  isCorrect: false,
                },
              ],
            },
            stat: {
              eyebrow: 'Your answers:',
              chartType: 'circle-counter',
              colorScheme: 'purple-50-scheme',
              showHeading: true,
              chartIcon: '',
              value: 80,
              unit: '%',
              description:
                'of physicians say that a <span>staffing shortage</span> is <span>the top issue</span> impacting their organization.',
              detail: { heading: '', description: '' },
            },
          },
        ],
      },
      {
        heading: 'Documentation trends',
        cards: [
          {
            type: 'double-stat-unified',
            eyebrow: 'Median clinician spends',
            stats: [
              {
                eyebrow: '',
                chartType: 'map-percentage',
                sizeClass: 'half-card',
                colorScheme: 'purple-50-scheme',
                showHeading: false,
                value: 21.5,
                unit: 'hours',
                description: 'working in the <span>EHR per week</span>',
              },
              {
                eyebrow: '',
                chartType: 'map-percentage',
                sizeClass: 'half-card',
                colorScheme: 'purple-50-scheme',
                showHeading: false,
                value: 21.5,
                unit: '%',
                description: 'of their EHR time <span>outside of patient hours</span>',
              },
            ],
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'simple-vertical-bar',
            sizeClass: 'half-card',
            colorScheme: 'purple-50-scheme',
            showHeading: false,
            description: '<span>Female clinicians</span> spend <span>more time</span> per patient in the EHR than male clinicians ',
            categories: [
              {
                type: '',
                hideValue: true,
                items: [
                  {
                    label: '',
                    icon: '/assets/svg/male-icon.svg',
                    value: 28.4,
                  },
                  {
                    label: '',
                    icon: '/assets/svg/female-icon.svg',
                    value: 50,
                  },
                ],
              },
            ],
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'clock',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            hours: 0,
            mins: 25.2,
            description:
              'Small practices (1-6 clinicians) have the <span>highest time per patient</span> (TPR) visit:<br/><span class="value-label">25.2</span><span class="clock-unit-label"> minutes</span>',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'clock',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            hours: 0,
            mins: 21,
            description:
              'Median clinician spends <br /><span class="value-label">21</span><span class="clock-unit-label"> min, </span><span class="value-label">40</span> <span class="clock-unit-label">sec</span><br/>per patient on documentation.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'video',
            alignmentClass: 'video-right',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            quote: '',
            description:
              'Clinicians are expected to do <span>45 minutes</span> worth of care and documentation in <span>15-minute visits.</span>',
            detail: { heading: '', description: '' },
          },
        ],
      },
      {
        heading: 'Specialty Trends',
        cards: [
          {
            type: 'stat',
            chartType: 'tabs-vertical-bar',
            sizeClass: 'full-card',
            colorScheme: 'purple-50-scheme',
            showHeading: false,
            description: '',
            categories: [
              {
                label: 'Hours Per Week in EHR',
                items: [
                  {
                    label: 'Orthopedic surgeons',
                    value: 17.9,
                    unit: 'hrs',
                  },
                  {
                    label: 'OB/GYNs',
                    value: 18.2,
                    unit: 'hrs',
                  },
                  {
                    label: 'Pediatricians',
                    value: 19.3,
                    unit: 'hrs',
                  },
                  {
                    label: 'Primary Care',
                    value: 23.3,
                    unit: 'hrs',
                  },
                  {
                    label: 'Cardiologists',
                    value: 19.8,
                    unit: 'hrs',
                  },
                  {
                    label: 'Neurologists',
                    value: 21.2,
                    unit: 'hrs',
                  },
                  {
                    label: 'Mental Health',
                    value: 20,
                    unit: 'hrs',
                  },
                ],
              },
              {
                label: 'Time spent in EHR outside patient hours',
                items: [
                  {
                    label: 'Orthopedic surgeons',
                    value: 31.3,
                    unit: '%',
                  },
                  {
                    label: 'OB/GYNs',
                    value: 30.6,
                    unit: '%',
                  },
                  {
                    label: 'Pediatricians',
                    value: 20,
                    unit: '%',
                  },
                  {
                    label: 'Primary Care',
                    value: 21.1,
                    unit: '%',
                  },
                  {
                    label: 'Cardiologists',
                    value: 23.1,
                    unit: '%',
                  },
                  {
                    label: 'Neurologists',
                    value: 20.7,
                    unit: '%',
                  },
                  {
                    label: 'Mental Health',
                    value: 16.5,
                    unit: '%',
                  },
                ],
              },
              {
                label: 'Average number of patients per week',
                items: [
                  {
                    label: 'Orthopedic surgeons',
                    value: 75,
                    unit: '',
                  },
                  {
                    label: 'OB/GYNs',
                    value: 69,
                    unit: '',
                  },
                  {
                    label: 'Pediatricians',
                    value: 65,
                    unit: '',
                  },
                  {
                    label: 'Primary Care',
                    value: 64,
                    unit: '',
                  },
                  {
                    label: 'Cardiologists',
                    value: 62,
                    unit: '',
                  },
                  {
                    label: 'Neurologists',
                    value: 53,
                    unit: '',
                  },
                  {
                    label: 'Mental Health',
                    value: 44,
                    unit: '',
                  },
                ],
              },
            ],
            detail: { heading: '', description: '' },
          },
        ],
      },
      {
        heading: 'Technology',
        cards: [
          {
            type: 'quiz',
            sizeClass: 'half-card',
            fullSizeOnMobile: false,
            quiz: {
              type: 'radio-short',
              question: '____ % of clinicians say they would be OK using AI for clinical decision support.',
              options: [
                {
                  label: '22%',
                  isCorrect: false,
                },
                {
                  label: '41%',
                  isCorrect: false,
                },
                {
                  label: '58%',
                  isCorrect: true,
                },
                {
                  label: '76%',
                  isCorrect: false,
                },
              ],
            },
            stat: {
              eyebrow: 'Your answers:',
              chartType: 'clock',
              colorScheme: 'purple-50-scheme',
              showHeading: true,
              hours: 5,
              mins: 0,
              description:
                '<span class="value-label">5</span><span class="clock-unit-label"> hours per week</span><br/>per employee are wasted on tasks that could be automated.',
              detail: { heading: '', description: '' },
            },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            chartIcon: '/assets/svg/hand-shake-heart-icon.svg',
            value: 77,
            unit: '%',
            description:
              'of <span>admin staff</span> say that their use of technology <span>enhances their relationships</span> with patients.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'one-third-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            value: 57,
            unit: '%',
            description:
              'of clinicians say that <span>slow or limited access</span> to medical records is the <span>biggest pain point</span> in the world of interoperability.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'horizontal-percentage',
            sizeClass: 'one-third-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            value: 94,
            unit: '%',
            description: 'of physicians say that <span>increased interoperability will improve</span> patient outcomes.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'circle-counter',
            sizeClass: 'one-third-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            value: 91,
            unit: '%',
            description: 'of physicians say that increased interoperability will <span>improve the clinician experience.</span>',
            detail: { heading: '', description: '' },
          },
          {
            type: 'quiz',
            sizeClass: 'half-card',
            fullSizeOnMobile: false,
            quiz: {
              type: 'radio-short',
              question: '____ % of clinicians say they would be OK using AI for clinical decision support.',
              options: [
                {
                  label: '22%',
                  isCorrect: false,
                },
                {
                  label: '41%',
                  isCorrect: true,
                },
                {
                  label: '58%',
                  isCorrect: false,
                },
                {
                  label: '76%',
                  isCorrect: false,
                },
              ],
            },
            stat: {
              eyebrow: 'Your answers:',
              chartType: 'horizontal-percentage',
              colorScheme: 'honey-scheme',
              showHeading: true,
              chartIcon: '',
              value: 41,
              unit: '%',
              description: 'of clinicians say they would be <span>OK using AI</span> for clinicial decision support.',
              detail: { heading: '', description: '' },
            },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            chartIcon: '/assets/svg/ai-icon-purple.svg',
            value: 46,
            unit: '%',
            description:
              'of clinicians are <span>unsure</span> whether they would <span>trust AI</span> to determine what clinical data was relevant at the point of care.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat-copy-only',
            icon: '/assets/svg/ai-icon-honey.svg',
            chartType: '',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            title: '',
            description:
              'Respondents in <span>larger organizations</span> are more likely to <span>trust AI</span> than their counterparts at smaller organizations.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'circle-count',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            chartIcon: '/assets/svg/ai-icon-purple.svg',
            value: 90,
            unit: '%',
            description:
              'of physicians agree that having <span>better data<span> about patients increases their confidence in supporting their needs.',
            detail: { heading: '', description: '' },
          },
        ],
      },
      {
        heading: 'Future Outlook',
        cards: [
          {
            type: 'stat',
            chartType: 'health-percentage',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            value: 25,
            unit: '%',
            description: 'of healthcare workers think about <span>leaving the field</span> in the near future.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'horizontal-percentage',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'purple-50-scheme',
            value: 52,
            unit: '%',
            description: 'of physicians are <span>pessimistic</span> about the <span>future of healthcare in the US.</span>',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'full-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            chartIcon: '/assets/svg/right-direction-icon.svg',
            value: 53,
            unit: '%',
            description: 'of <span>admin staff</span> believe the healthcare industry is <span>moving in the right direction.</span>',
            detail: { heading: '', description: '' },
          },
        ],
      },
    ],
  },
  practiceSection: {
    id: 'practice-section',
    heading: 'Practice Trends',
    categories: [
      {
        heading: 'Financial Performance',
        cards: [],
      },
      {
        heading: 'Specialty Trends',
        cards: [],
      },
    ],
  },
  patientsSection: {
    id: 'patients-section',
    heading: 'Patients',
    categories: [
      {
        heading: 'Telehealth Trends',
        cards: [
          {
            type: 'stat-copy-only',
            icon: '/assets/svg/appointment-icon.svg',
            chartType: '',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            title: '',
            description:
              'Patients were more likely to use telehealth for <span>same-day appointments</span>, and were more likely to <span>get a new diagnosis at that appointment</span>, compared to in-person appointments. ',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat-copy-only',
            icon: '/assets/svg/appointment-icon.svg',
            chartType: '',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            title: '',
            description:
              'Telehealth patients who received a <span>new physical health diagnosis</span> were more likely to attend a <span>follow-up in person</span>. <br/><br/>Telehealth patients who received a <span>new mental health diagnosis</span> were more likely to <span>attend a telehealth appointment</span>. ',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat-copy-only',
            icon: '/assets/svg/telehealth-icon.svg',
            chartType: '',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            title: '',
            description:
              'There is strong evidence that telehealth is being used as a <span>part of a care continuum</span> (filling care gaps for patients) rather than simply replacing in-person visits.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat-copy-only',
            icon: '/assets/svg/telehealth-increase-icon.svg',
            chartType: '',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            title: '',
            description:
              '<span>45%</span> of survey respondents <span>would like</span> to use telehealth in the future.<br/><br/><span>That increases to 61%<span> among respondents who have experience using telehealth in the last two years.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'double-stat',
            stats: [
              {
                eyebrow: 'Hispanic patients were',
                chartType: '',
                colorScheme: 'purple-50-scheme',
                showHeading: false,
                value: 51,
                unit: '%',
                description: 'more likely to have a telehealth appointment (versus in person) than white patients.',
              },
              {
                eyebrow: 'Black patients were',
                chartType: '',
                colorScheme: 'purple-50-scheme',
                showHeading: false,
                value: 20,
                unit: '%',
                description: 'more likely to have a telehealth appointment (versus in person) than white patients.',
              },
            ],
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'single-circular',
            sizeClass: 'full-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            value: 22,
            chartIcon: '/assets/svg/telehealth-icon.svg',
            unit: '%',
            description:
              'Telehealth is <span>extremely popular for mental health diagnoses</span>, across all age groups. <span>Gen Z and Millennials</span> were the <span>heaviest users</span>, with each group receiving over <span>22%</span> of their depression and anxiety visits virtually. ',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat-copy-only',
            icon: '/assets/svg/telehealth-plus-icon.svg',
            chartType: '',
            sizeClass: 'half-card',
            showHeading: true,
            colorScheme: 'honey-scheme',
            title: '',
            description:
              'Patients with <span>urban zip</span> codes rely on telehealth appointments anywhere from <span>30% to 50%</span> more often than those with rural zip codes.',
            detail: { heading: '', description: '' },
          },
          {
            type: 'quiz',
            sizeClass: 'half-card',
            fullSizeOnMobile: false,
            quiz: {
              type: 'radio-long',
              question: 'Which providers are more likely to provide a telehealth appointment?',
              options: [
                {
                  label: 'Male',
                  isCorrect: false,
                },
                {
                  label: 'Female',
                  isCorrect: true,
                },
              ],
            },
            stat: {
              eyebrow: 'Your answers:',
              chartType: 'simple-vertical-bar',
              colorScheme: 'purple-50-scheme',
              showHeading: true,
              chartIcon: '',
              categories: [
                {
                  type: '',
                  hideValue: true,
                  items: [
                    {
                      label: '',
                      icon: '/assets/svg/male-icon.svg',
                      value: 26,
                    },
                    {
                      label: '',
                      icon: '/assets/svg/female-icon.svg',
                      value: 50,
                    },
                  ],
                },
              ],
              description:
                'Male providers are <span>24%</span> less likely to <span>provide a telehealth appointment</span> than female providers.',
              detail: { heading: '', description: '' },
            },
          },
        ],
      },
    ],
  },
  healthcareSection: {
    id: 'healthcare-section',
    heading: 'Healthcare',
    categories: [
      {
        heading: '',
        cards: [
          {
            type: 'double-stat',
            stats: [
              {
                eyebrow: '',
                chartType: 'map-percentage',
                sizeClass: 'half-card',
                colorScheme: 'honey-scheme',
                showHeading: false,
                value: 60,
                unit: '%',
                description: 'of adult Americans have at least 1 chronic condition',
              },
              {
                eyebrow: '',
                chartType: 'map-percentage',
                sizeClass: 'half-card',
                colorScheme: 'purple-50-scheme',
                showHeading: false,
                value: 40,
                unit: '%',
                description: 'of adult Americans have at least 2+ chronic conditions',
              },
            ],
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'flu-trend-map',
            sizeClass: 'half-card',
            showHeading: false,
            colorScheme: 'purple-50-scheme',
            categories: [
              {
                label: 'Peak',
                imageUrl: '/assets/svg/flu-trend-peak.svg',
              },
              {
                label: 'Low',
                imageUrl: '/assets/svg/flu-trend-low.svg',
              },
              {
                label: 'Latest',
                imageUrl: '/assets/svg/flu-trend-latest.svg',
              },
            ],
            title: '',
            description: '',
            detail: { heading: '', description: '' },
          },
          {
            type: 'quiz',
            sizeClass: 'half-card',
            fullSizeOnMobile: false,
            quiz: {
              type: 'radio-long',
              question: 'Which generation has experienced the most rapid growth in anxiety diagnoses since 2021?',
              options: [
                {
                  label: 'Gen Z',
                  isCorrect: true,
                },
                {
                  label: 'Gen X',
                  isCorrect: false,
                },
                {
                  label: 'Millennial',
                  isCorrect: false,
                },
                {
                  label: 'Baby Boomer',
                  isCorrect: false,
                },
              ],
            },
            stat: {
              type: 'stat',
              eyebrow: 'Your answers:',
              chartType: 'horizontal-bar',
              colorScheme: 'purple-50-scheme',
              categories: [
                {
                  label: 'Millenial',
                  value: 28.4,
                },
                {
                  label: 'Gen Z',
                  pointyCorner: true,
                  value: 50,
                },
              ],
              description:
                'Millennials were the <span>most likely to have an anxiety diagnosis</span>, particularly in 2021 and beyond, but <span>Gen Z experienced the most rapid growth</span> in anxiety diagnoses. ',
              detail: { heading: '', description: '' },
            },
          },
          {
            type: 'stat',
            chartType: 'simple-vertical-bar',
            sizeClass: 'half-card',
            colorScheme: 'purple-50-scheme',
            showHeading: false,
            description: '<span>Female clinicians</span> spend <span>more time</span> per patient in the EHR than male clinicians ',
            categories: [
              {
                type: '',
                hideValue: false,
                items: [
                  {
                    label: 'medium org.',
                    value: 28.4,
                  },
                  {
                    label: 'large org.',
                    value: 50,
                  },
                  {
                    label: 'small org.',
                    value: 10,
                  },
                ],
              },
            ],
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'single-pie-chart',
            sizeClass: 'half-card',
            colorScheme: 'purple-50-scheme',
            showHeading: true,
            chartIcon: '/assets/svg/battery-icon.svg',
            value: 57,
            unit: '%',
            description: 'of admin staff are worried they will <span>burn out due to the high volume of repetitive tasks</span>',
            detail: { heading: '', description: '' },
          },
          {
            type: 'stat',
            chartType: 'diagnoses-line-chart',
            sizeClass: 'full-card',
            colorScheme: 'purple-50-scheme',
            categories: [
              {
                label: 'How depression diagnoses have grown by generation',
                imageUrl: '/assets/svg/depression-line-chart.svg',
              },
              {
                label: 'How anxiety diagnoses have grown by generation',
                imageUrl: '/assets/svg/anxiety-line-chart.svg',
              },
            ],
            detail: { heading: '', description: '' },
          },
        ],
      },
    ],
  },
};
