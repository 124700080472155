import { useState } from 'react';
import PropTypes from 'prop-types';
import MapPercentageChart from '../../atoms/mapPercentageChart';
import useScreenSize from '../../../utils/useScreenSize';
import { ChartTypes } from '../../../data/constant';
import CardFooter from '../cardFooter';
import CardDetail from '../cardDetail';

const DoubleStatCard = ({ data, props }) => {
    const [revealContent, setRevealContent] = useState(false)
    const screen = useScreenSize();

    const getContentValue = (stat) => {
        if (stat.value) {
            return <h4 className={`value-label ${'color-' + stat.colorScheme}`}>{stat.value}<span className={`unit-label`}>{" " + stat.unit}</span></h4>
        }
        return null;
    }

    const getContentDescription = (stat) => {
        if (stat.description) {
            return <p className={`${'color-' + stat.colorScheme} l-sub description-label`} dangerouslySetInnerHTML={{ __html: stat.description }}></p>
        }
        return null;
    }

    const getStatEyebrow = (stat) => {
        if (stat.eyebrow) {
            return <p className={`${'color-' + stat.colorScheme} l-sub eyebrow`} dangerouslySetInnerHTML={{ __html: stat.eyebrow }}></p>
        }
        return null;
    }

    const getContentComponent = (stat) => {
        switch (stat.chartType) {
            case ChartTypes.mapPercentage:
                return <MapPercentageChart data={stat} />
            default:
                return null;
        }
    }

    const contentRevealHandler = () => {
        setRevealContent(!revealContent);
    }

    const getContentLayout = (stat, index) => {
        return <div key={`double-stat-item-` + index} className="double-stat-item">
            {getStatEyebrow(stat)}
            <div className='double-stat-top-content'>
                {getContentComponent(stat, index)}
                {getContentValue(stat)}
            </div>
            <div className='double-stat-bottom-content'>
                {getContentDescription(stat)}
            </div>
        </div>
    }

    const getDobuleStateUnifiedContent = () => {
        return <>
            {data.stats.map((stat, index) => {
                return <div key={`double-stat-item-` + index} className="double-stat-item center-txt">
                    {stat.value && <h4 className={`value-label ${'color-' + stat.colorScheme}`}>{stat.value}<span className={`unit-label`}>{" " + stat.unit}</span></h4>}
                    {stat.description && <p className={`${'color-' + stat.colorScheme} ${screen.isMobile ? 'm-sub' : 'l-sub'} description-label`} dangerouslySetInnerHTML={{ __html: stat.description }} />}
                </div>
            })}
        </>
    }

    const getRegularDoubleStateContent = () => {
        return <>
            {data.stats[0] && getContentLayout(data.stats[0], 0)}
            <hr />
            {data.stats[1] && getContentLayout(data.stats[1], 1)}
        </>
    }

    return (
        <div className={`card-basic double-stat`}>
            <div className='card-content-wrapper'>
                {data.eyebrow && <p className={`double-stat-eyebrow ${screen.isMobile ? 'm-sub' : 'l-sub'}`}>{data.eyebrow}</p>}
                <div className={`card-content-detail`}>
                    {data.stats && data.type === 'double-stat' ? getRegularDoubleStateContent() : getDobuleStateUnifiedContent()}
                </div>
                <CardFooter data={data} revealContent={revealContent} contentRevealHandler={contentRevealHandler} />
            </div>
            <CardDetail data={data} revealContent={revealContent} />
        </div>
    )
}

DoubleStatCard.propTypes = {
    data: PropTypes.object.isRequired,
}

export default DoubleStatCard;