import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StatCard from '../statCard';
import useScreenSize from '../../../utils/useScreenSize';

const InteractionCard = ({ data, props }) => {
    const screen = useScreenSize();
    const quizRef = useRef();
    const statCardRef = useRef();
    const [selectedOption, setSelectedOption] = useState(-1);
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isResized, setIsResized] = useState(false);
    const [quizBigger, setQuizBigger] = useState(false);

    useEffect(() => {
        let quizH = quizRef.current.clientHeight;
        let statH = statCardRef.current.clientHeight;
        if (!isResized) {
            if (quizH >= statH) {
                setQuizBigger(true);
                setContainerHeight(quizH)
            } else {
                setContainerHeight(statH)
            }
            setIsResized(true)
        }
        if (data.quiz.question === "Which providers are more likely to provide a telehealth appointment?") {
            console.log(data.quiz.question)
            console.log('quizBigger', quizBigger)
        }
    }, [screen]);

    useEffect(() => {
        let quizH = quizRef.current.clientHeight;
        let statH = statCardRef.current.clientHeight;
        if (quizBigger) {
            setContainerHeight(quizH)
        } else {
            setContainerHeight(statH)
        }
    }, [quizBigger, screen.screenSize])

    const submitHandler = () => {
        setSubmitted(true);
        quizRef.current.style.opacity = 0;
        quizRef.current.style.pointerEvents = 'none';
    }


    const getQuizContent = () => {
        if (data.quiz.type === 'radio-long' || data.quiz.type === 'radio-short') {
            let optionGroupStyle = data.quiz.type === 'radio-long' ? 'quiz-options-group' : 'quiz-options-group-short';
            let optionStyle = data.quiz.type === 'radio-long' ? 'quiz-option' : 'quiz-option quiz-option-short';
            let radioStyle = data.quiz.type === 'radio-long' ? 'custom-radio' : 'custom-radio custom-radio-short';
            if (data.quiz && data.quiz.options) {
                return <div className='interaction-card-content' ref={quizRef} style={{ minHeight: isResized ? containerHeight : 'none' }}>
                    <div className='card-content-wrapper interactive-card-content-wrapper' >
                        <p className='sm-sub card-eyebrow'>Take a quess</p>
                        <h4 className={`quiz-question-label ${screen.isMobile ? 'xs-title' : 'sm-title'}`}>{data.quiz.question}</h4>
                        {data.quiz.options && <div className={optionGroupStyle}>
                            {data.quiz.options.map((option, index) => {
                                return <label key={`quiz-option-${index}`} className={`${optionStyle} ${selectedOption === index ? 'selected' : ''}`}>
                                    <input type="radio" className={radioStyle} value={`option-${index}`} checked={selectedOption === index} onChange={() => {
                                        setSelectedOption(index);
                                        setSelectedDetail(option);
                                    }} />
                                    {option.label}
                                </label>
                            })}

                        </div>}
                        <button type="submit" className='submit-btn' disabled={selectedOption === -1} onClick={submitHandler}>Submit</button>
                    </div>
                </div>
            }
        }

        return null;
    }

    return (
        <div className={`card-basic interaction-card ${data.sizeClass ?? ''}`}>
            {/* offset 8 since border witdth is 4 on each side */}
            <div className='stat-card-container' style={{ height: quizBigger ? (containerHeight + 8) + 'px' : 'auto' }}>
                <div ref={statCardRef} style={{ display: 'flex', height: '100%' }}>
                    {<StatCard data={data.stat} quizResult={selectedDetail} />}
                </div>
            </div>
            {getQuizContent()}
        </div>
    )
}

InteractionCard.propTypes = {
    data: PropTypes.object.isRequired,
}

export default InteractionCard;